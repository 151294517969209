import EventAgent from '@hotmart/event-agent-js';
import { REQUIRED_FIELDS, EVENT_PROPS } from './datahub.constants';

const isProduction = process.env.ENV_CONFIG === 'production';

const getCommonEventDataByAction = (action: string): DataHubAction => {
  const eventData = EVENT_PROPS[action];

  if (!eventData) {
    if (!isProduction) {
      throw new Error(`Invalid DataHub action. Received: ${action}`);
    }
    return {} as DataHubAction;
  }

  return { ...eventData };
};

const validateEventRequiredFields = (event: DataHubAction, eventData: DataHubAction): boolean => {
  const requiredFields = REQUIRED_FIELDS[event.action] || [];
  const isInvalid = requiredFields.some(field => [null, undefined, ''].includes(event[field]));

  if (isInvalid && !isProduction) {
    console.error(`Invalid DataHub event data. Received:`, eventData);
  }

  return isInvalid;
};

export function sendEvent(event: DataHubAction): void {
  const { action, ...restEvent } = event;
  const eventData = getCommonEventDataByAction(action);

  if (!eventData) return;

  eventData.event = restEvent;
  eventData.data_version = restEvent.data_version || eventData.data_version;

  const isInvalid = validateEventRequiredFields(event, eventData);

  if (!isInvalid) {
    if (!isProduction) {
      console.log('DATAHUB EVENT:', JSON.stringify(eventData, null, 2));
    }

    EventAgent.mode = isProduction ? 'production' : 'staging';
    EventAgent.send(eventData);
  }
}

function isOrganicSearch(referrer) {
  const searchEngines = [
    'google.com',
    'bing.com',
    'yahoo.com',
    'duckduckgo.com',
  ];
  
  return searchEngines.some(engine => referrer.includes(engine));
}

export function getDetailedTrafficSource(referrer: string) {
  if (!referrer) return 'direct'
  if (isOrganicSearch(referrer)) return 'seo';
  if (referrer.includes('club.hotmart.com')) return 'club';
  if (referrer.includes('app.hotmart.com')) return 'app_hotmart';
  if (referrer.includes(window.location.hostname)) return 'site_hotmart';
  return 'other_referral';
}
