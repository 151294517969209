import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { PropsWithChildren } from 'react';
import { treatedPageURL } from '@core/presentation/utils/urls/';
import { verifyLanguage } from '@core/presentation/utils/language';

const MainFooter = dynamic(
  () => import('@hotmart/app-display-components/dist/MainFooter'),
  { ssr: false }
);

type Props = {
  lng: string;
  showFooter?: Boolean;
  project?: string;
  environment?: string;
  categoriesLinks?: string;
  isMarketplace?: Boolean;
  user?: string;
  myShoppingLabel?: string;
};

function Footer(props: PropsWithChildren<Props>) {
  const {
    lng,
    showFooter = true,
    project,
    environment,
    categoriesLinks,
    isMarketplace,
    user,
    myShoppingLabel
  } = props;
  const isSimplified = true;
  const router = useRouter();

  const changeLanguage = language => {
    const { query } = router;
    const lngTreated = verifyLanguage(language);
    const url = treatedPageURL(language, query, router.asPath);

    Cookies.set('hmLangCookie', lngTreated, { path: '/' });

    document.location.href = url;
  };

  return (
    // @ts-ignore
    <MainFooter
      simplified={isSimplified}
      showFooter={showFooter}
      user={user}
      myShoppingLabel={myShoppingLabel}
      changeLanguage={changeLanguage}
      language={lng}
      project={project}
      categoriesLinks={categoriesLinks}
      isMarketplace={isMarketplace}
      environment={environment}
    >
      {props.children}
    </MainFooter>
  );
}

export default Footer;
